import PropTypes from 'prop-types';
import { Link as RouterLink } from 'react-router-dom';
// @mui
import { useTheme } from '@mui/material/styles';
import { Box } from '@mui/material';

// ----------------------------------------------------------------------

Logo.propTypes = {
  disabledLink: PropTypes.bool,
  sx: PropTypes.object,
};

export default function Logo({ disabledLink = false, sx }) {
  const theme = useTheme();
  const PRIMARY_LIGHT = theme.palette.primary.light;
  const PRIMARY_MAIN = theme.palette.primary.main;
  const PRIMARY_DARK = theme.palette.primary.dark;

  const logo = (
    <Box sx={{ width: 40, height: 40, ...sx }}>
   <svg xmlns="http://www.w3.org/2000/svg"   width="100%" height="100%" viewBox="0 0 640.000000 640.000000">
        <defs>
          <linearGradient id="BG1" x1="100%" x2="50%" y1="9.946%" y2="50%">
            <stop offset="0%" stopColor={PRIMARY_DARK} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
          <linearGradient id="BG2" x1="50%" x2="50%" y1="0%" y2="100%">
            <stop offset="0%" stopColor={PRIMARY_LIGHT} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
          <linearGradient id="BG3" x1="50%" x2="50%" y1="0%" y2="100%">
            <stop offset="0%" stopColor={PRIMARY_LIGHT} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
        </defs>
        <g 
fill={PRIMARY_MAIN}  stroke="none" strokeWidth="1"  fillRule="evenodd" transform="translate(0.000000,640.000000) scale(0.100000,-0.100000)">
<path d="M3075 5293 c-87 -169 -218 -427 -293 -573 -74 -146 -181 -355 -237
-465 -56 -110 -180 -355 -275 -545 -95 -190 -219 -433 -275 -540 -105 -199
-471 -914 -609 -1186 -42 -84 -76 -157 -76 -163 0 -8 104 -11 376 -9 l375 3
60 115 c33 63 166 324 296 580 130 256 273 537 318 625 79 152 341 663 443
862 26 51 51 92 55 90 10 -3 68 -112 292 -552 92 -181 171 -334 175 -340 4 -5
87 -167 185 -360 98 -192 181 -354 185 -360 4 -5 81 -155 170 -332 l163 -323
379 0 c208 0 378 2 378 5 0 2 -11 24 -23 47 -27 49 -225 437 -512 1003 -175
345 -232 452 -210 393 7 -16 -2 -18 -99 -18 l-106 0 0 202 c0 119 4 198 9 192
5 -5 48 -87 96 -182 48 -96 89 -171 92 -169 2 3 -27 65 -65 139 -38 73 -149
291 -247 483 -98 193 -228 449 -290 570 -62 121 -186 364 -275 540 -151 297
-290 566 -296 573 -1 2 -73 -135 -159 -305z"/>
<path d="M1210 4282 c0 -4 13 -32 28 -62 16 -30 82 -158 147 -285 66 -126 145
-279 177 -340 32 -60 108 -208 169 -328 l109 -219 -251 -491 c-138 -271 -280
-547 -315 -614 -35 -67 -64 -124 -64 -127 0 -12 58 -6 67 7 6 6 155 300 333
652 177 352 386 764 463 915 77 151 149 291 158 310 l17 35 -135 265 c-75 146
-140 271 -146 278 -7 9 -96 12 -383 12 -206 0 -374 -3 -374 -8z"/>
<path d="M4176 4083 c-6 -24 -7 -104 0 -125 5 -16 20 -18 125 -18 l119 0 0 80
0 80 -119 0 c-105 0 -120 -2 -125 -17z m94 -28 c11 -13 9 -20 -10 -40 -20 -21
-21 -25 -7 -25 9 0 17 -4 17 -10 0 -5 -13 -10 -30 -10 -37 0 -37 6 -4 41 26
28 26 61 -1 39 -8 -7 -15 -7 -19 -1 -6 9 8 19 30 20 6 1 17 -6 24 -14z m112
-20 c2 -19 0 -41 -4 -48 -6 -9 -8 -9 -8 1 0 6 -11 12 -25 12 -31 0 -32 16 -3
47 29 31 35 29 40 -12z"/>
<path d="M4347 4033 c-12 -11 -8 -23 8 -23 8 0 15 7 15 15 0 16 -12 20 -23 8z"/>
<path d="M4430 4020 l0 -80 65 0 65 0 0 80 0 80 -65 0 -65 0 0 -80z m100 36
c0 -7 -7 -19 -15 -26 -8 -7 -15 -23 -15 -36 0 -13 -4 -24 -10 -24 -16 0 -12
42 7 62 15 17 15 18 -5 18 -11 0 -24 5 -27 10 -4 6 8 10 29 10 24 0 36 -5 36
-14z"/>
<path d="M4160 3917 c0 -6 48 -102 106 -212 58 -110 124 -235 147 -279 33 -63
43 -74 49 -60 6 14 7 11 5 -11 -2 -29 49 -145 64 -145 4 0 10 10 12 23 4 21 4
21 6 -3 1 -20 3 -22 11 -10 8 13 10 12 10 -3 0 -10 -6 -17 -14 -15 -20 4 -19
-10 1 -57 18 -39 18 -31 21 364 1 222 0 406 -2 408 -2 3 -97 6 -210 9 -158 3
-206 1 -206 -9z m254 -412 l7 -29 -26 27 c-15 14 -25 32 -22 39 4 8 6 7 6 -4
1 -10 5 -18 10 -18 5 0 7 10 4 23 -7 33 13 -1 21 -38z m28 -77 c-9 -9 -12 -7
-12 12 0 19 3 21 12 12 9 -9 9 -15 0 -24z m20 -30 c-7 -7 -12 -8 -12 -2 0 6 3
14 7 17 3 4 9 5 12 2 2 -3 -1 -11 -7 -17z m52 -64 c11 -40 -9 -51 -23 -13 -14
40 -14 39 3 39 8 0 17 -12 20 -26z m16 -75 c0 -6 -4 -7 -10 -4 -5 3 -10 11
-10 16 0 6 5 7 10 4 6 -3 10 -11 10 -16z"/>
<path d="M3130 3273 c0 -5 -18 -9 -40 -9 -35 -2 -101 -29 -192 -79 -37 -21
-124 -119 -159 -179 -156 -271 -42 -631 243 -761 132 -60 274 -68 407 -21 220
77 361 273 361 503 0 48 -7 113 -15 143 -17 65 -55 150 -67 150 -4 0 -8 7 -8
15 0 35 -159 175 -210 187 -8 2 -20 8 -27 15 -31 29 -293 61 -293 36z m110
-13 c11 -7 7 -10 -17 -10 -17 0 -38 -3 -47 -6 -9 -3 -16 -1 -16 4 0 9 19 16
55 20 6 1 17 -3 25 -8z m-143 -16 c-3 -3 -12 -4 -19 -1 -8 3 -5 6 6 6 11 1 17
-2 13 -5z m273 -4 c11 -8 7 -9 -15 -4 -37 8 -45 14 -19 14 10 0 26 -5 34 -10z
m-295 -30 c-3 -5 -12 -10 -18 -10 -7 0 -6 4 3 10 19 12 23 12 15 0z m485 -82
c0 -6 -7 -5 -15 2 -8 7 -15 17 -15 22 0 6 7 5 15 -2 8 -7 15 -17 15 -22z
m-666 1 c4 -4 -4 -6 -18 -6 -21 0 -24 2 -15 13 7 8 15 11 18 7 3 -4 10 -11 15
-14z m-58 -48 c-13 -15 -14 -20 -4 -24 10 -4 10 -6 0 -6 -22 -2 -26 29 -6 44
25 18 31 10 10 -14z m784 -5 c0 -3 -4 -8 -10 -11 -5 -3 -10 -1 -10 4 0 6 5 11
10 11 6 0 10 -2 10 -4z m20 -20 c0 -2 -7 -7 -16 -10 -8 -3 -12 -2 -9 4 6 10
25 14 25 6z m-840 -77 c0 -6 -4 -7 -10 -4 -5 3 -10 11 -10 16 0 6 5 7 10 4 6
-3 10 -11 10 -16z m553 -21 c3 -8 -4 -13 -19 -13 -24 0 -30 6 -17 18 11 11 30
8 36 -5z m-310 -10 c-2 -5 1 -8 8 -8 29 0 87 -53 109 -100 19 -40 22 -60 18
-114 -7 -78 -34 -127 -92 -163 -36 -23 -54 -27 -148 -31 l-108 -5 0 214 0 214
109 0 c60 0 107 -3 104 -7z m267 -37 c9 -23 20 -38 25 -34 4 4 5 2 1 -5 -5 -7
-3 -26 3 -43 6 -16 16 -42 22 -59 6 -16 16 -27 21 -24 5 4 9 -2 8 -12 -8 -70
3 -51 61 111 17 50 35 95 40 100 4 6 30 8 58 7 l51 -4 -15 -28 c-19 -37 -19
-40 3 -34 9 2 7 -1 -6 -7 -13 -8 -27 -30 -36 -58 -7 -25 -21 -64 -31 -86 -9
-22 -31 -76 -48 -120 l-32 -80 -40 -3 -40 -3 -24 58 c-63 154 -132 346 -128
357 3 6 23 11 47 10 41 -1 44 -3 60 -43z m170 45 c0 -3 -4 -8 -10 -11 -5 -3
-10 -1 -10 4 0 6 5 11 10 11 6 0 10 -2 10 -4z m-135 -46 c3 -5 1 -10 -4 -10
-6 0 -11 5 -11 10 0 6 2 10 4 10 3 0 8 -4 11 -10z m-634 -41 c-5 -13 -10 -19
-10 -12 -1 15 10 45 15 40 3 -2 0 -15 -5 -28z m1007 -4 c-3 -3 -9 2 -12 12 -6
14 -5 15 5 6 7 -7 10 -15 7 -18z m-122 -22 c-10 -10 -19 5 -10 18 6 11 8 11
12 0 2 -7 1 -15 -2 -18z m138 -45 c2 -24 -1 -52 -5 -63 -5 -12 -7 6 -7 48 0
74 9 84 12 15z m-27 10 c-3 -8 -6 -5 -6 6 -1 11 2 17 5 13 3 -3 4 -12 1 -19z
m-1012 2 c3 -5 1 -10 -4 -10 -6 0 -11 5 -11 10 0 6 2 10 4 10 3 0 8 -4 11 -10z
m898 -22 c-7 -46 -13 -48 -13 -5 0 21 4 37 9 37 5 0 7 -15 4 -32z m-370 7 c-3
-9 -8 -14 -10 -11 -3 3 -2 9 2 15 9 16 15 13 8 -4z m339 -12 c-3 -16 -7 -22
-10 -15 -4 13 4 42 11 42 2 0 2 -12 -1 -27z m-180 -5 c-2 -10 -6 -18 -7 -18
-1 0 -5 8 -7 18 -3 11 0 19 7 19 7 0 10 -8 7 -19z m35 0 c-3 -8 -6 -5 -6 6 -1
11 2 17 5 13 3 -3 4 -12 1 -19z m-163 -44 c3 -8 2 -12 -4 -9 -6 3 -10 10 -10
16 0 14 7 11 14 -7z m-534 -107 c0 -5 -4 -5 -10 -2 -5 3 -10 14 -10 23 0 15 2
15 10 2 5 -8 10 -19 10 -23z"/>
<path d="M2930 2745 l0 -125 36 0 c20 0 33 4 29 10 -3 6 1 7 9 4 22 -8 62 39
69 81 6 38 -4 89 -19 91 -25 4 -54 17 -54 25 0 14 11 11 32 -8 11 -10 18 -12
18 -4 0 17 -61 51 -92 51 l-28 0 0 -125z m54 76 c3 -4 -6 -6 -19 -3 -14 2 -25
6 -25 8 0 8 39 4 44 -5z m66 -121 c0 -11 -2 -20 -4 -20 -2 0 -6 9 -9 20 -3 11
-1 20 4 20 5 0 9 -9 9 -20z m-65 -40 c3 -5 -1 -10 -10 -10 -9 0 -13 5 -10 10
3 6 8 10 10 10 2 0 7 -4 10 -10z"/>
<path d="M3311 2060 c-55 -6 -68 -12 -92 -40 -42 -48 -40 -93 7 -136 33 -32
40 -34 105 -34 55 0 69 3 69 15 0 12 -14 15 -64 15 -37 0 -76 6 -92 15 -16 8
-25 15 -20 15 4 0 2 10 -6 23 -9 14 -12 31 -7 47 4 14 7 22 8 18 1 -5 10 1 21
12 13 13 33 20 59 20 22 0 42 5 44 13 4 10 6 10 6 0 1 -7 12 -13 26 -13 18 0
25 5 25 20 0 11 -6 19 -12 19 -7 -1 -42 -5 -77 -9z"/>
<path d="M3440 1960 c0 -101 2 -111 17 -108 10 2 20 15 23 30 3 14 16 35 30
46 l25 20 35 -48 c19 -27 45 -50 58 -53 31 -6 28 6 -18 67 -22 29 -40 58 -40
64 0 6 18 26 40 43 45 36 50 49 19 49 -11 0 -49 -25 -84 -56 l-64 -55 -3 53
c-2 41 -7 54 -20 56 -16 3 -18 -7 -18 -108z"/>
<path d="M3702 2054 c-20 -14 -22 -24 -22 -98 0 -50 5 -87 12 -94 8 -8 47 -12
110 -12 80 0 98 3 98 15 0 12 -16 15 -85 15 -80 0 -86 2 -94 23 -15 39 -4 47
71 47 61 0 69 2 66 18 -3 13 -15 18 -63 20 -79 3 -91 8 -75 27 9 11 34 15 96
15 77 0 84 2 84 20 0 18 -7 20 -88 20 -63 0 -94 -4 -110 -16z"/>
<path d="M3930 1960 c0 -103 1 -110 20 -110 17 0 20 7 20 50 0 53 6 58 52 41
14 -5 32 -27 43 -50 14 -32 24 -41 42 -41 l25 0 -25 44 -25 45 24 22 c31 29
30 62 -2 88 -22 17 -41 21 -100 21 l-74 0 0 -110z m138 64 c12 -4 22 -13 22
-20 0 -10 -16 -14 -60 -14 -53 0 -60 2 -60 20 0 16 7 20 38 20 20 0 47 -3 60
-6z"/>
<path d="M2535 2050 c-7 -12 -8 -12 33 -15 18 -1 35 2 38 6 3 5 15 9 27 8 21
0 21 -1 2 -9 -19 -8 -19 -9 3 -9 12 -1 22 4 22 9 0 7 6 7 18 1 26 -14 72 -11
72 5 0 11 -22 14 -104 14 -59 0 -108 -4 -111 -10z"/>
<path d="M2796 2046 c5 -13 25 -16 105 -15 73 1 99 5 99 14 0 9 -28 13 -105
14 -90 2 -104 0 -99 -13z"/>
<path d="M2407 1954 c-47 -93 -53 -120 -23 -95 8 7 12 20 9 28 -5 12 -3 14 7
8 10 -6 12 -4 7 8 -4 10 -2 16 6 14 7 -1 11 7 10 21 -2 13 1 21 7 17 6 -3 13
1 16 9 10 27 19 19 53 -49 17 -36 38 -65 45 -65 9 0 12 7 9 21 -4 13 -8 17
-13 9 -5 -8 -9 -7 -14 5 -9 23 -8 27 5 19 6 -3 3 8 -5 25 -19 36 -30 41 -20 9
3 -13 3 -19 -2 -15 -4 4 -8 18 -9 30 -1 26 -26 97 -35 97 -3 -1 -27 -44 -53
-96z m63 62 c0 -2 -8 -10 -17 -17 -16 -13 -17 -12 -4 4 13 16 21 21 21 13z"/>
<path d="M3074 2038 c-12 -18 -94 -179 -94 -183 0 -2 8 -1 18 2 9 2 30 31 46
64 15 32 31 59 35 59 4 0 21 -29 39 -65 19 -36 40 -65 48 -65 19 0 18 2 -11
61 -14 27 -30 47 -35 44 -5 -3 -7 0 -4 8 8 21 -32 92 -42 75z m25 -45 c12 -27
12 -27 -7 -10 -19 18 -21 18 -31 0 -6 -10 -11 -15 -11 -10 0 10 24 47 31 47 3
0 11 -12 18 -27z m35 -69 c3 -8 2 -12 -4 -9 -6 3 -10 10 -10 16 0 14 7 11 14
-7z"/>
<path d="M2630 1949 c0 -44 4 -68 10 -64 6 3 10 1 10 -4 0 -6 -4 -11 -10 -11
-5 0 -10 -4 -10 -8 0 -5 7 -9 15 -9 12 0 15 15 15 76 0 53 -3 71 -10 61 -8
-13 -10 -13 -10 2 0 9 6 19 13 21 7 3 5 6 -5 6 -16 1 -18 -9 -18 -70z m17 -31
c-3 -8 -6 -5 -6 6 -1 11 2 17 5 13 3 -3 4 -12 1 -19z"/>
<path d="M2890 2010 c11 -7 11 -10 2 -10 -9 0 -12 -20 -11 -75 1 -56 5 -75 15
-75 11 0 14 20 14 85 0 74 -2 85 -17 85 -15 0 -16 -2 -3 -10z"/>
<path d="M2581 1736 c3 -43 5 -46 31 -46 22 0 29 6 34 27 9 34 -6 57 -40 61
-26 3 -27 2 -25 -42z m53 12 c7 -12 6 -21 -3 -32 -21 -25 -31 -19 -31 20 0 37
14 42 34 12z"/>
<path d="M2718 1735 c-10 -25 -13 -45 -8 -45 6 0 10 5 10 10 0 6 9 10 20 10
11 0 20 -4 20 -10 0 -5 4 -10 9 -10 10 0 -13 67 -28 82 -4 4 -14 -12 -23 -37z
m32 -6 c0 -5 -4 -9 -10 -9 -5 0 -10 7 -10 16 0 8 5 12 10 9 6 -3 10 -10 10
-16z"/>
<path d="M2823 1768 c9 -7 18 -29 20 -48 l4 -35 1 37 c1 23 8 41 20 47 14 8 9
10 -22 11 -34 0 -37 -2 -23 -12z"/>
<path d="M3082 1754 c-5 -16 -14 -38 -18 -49 -7 -18 -6 -19 11 -5 22 18 18 18
40 0 17 -14 17 -13 10 10 -4 14 -13 36 -20 49 l-12 24 -11 -29z"/>
<path d="M3211 1773 c0 -5 9 -26 18 -48 l18 -40 17 39 c9 22 15 44 13 50 -3 6
-11 -5 -20 -24 l-15 -35 -7 33 c-4 17 -11 32 -16 32 -5 0 -9 -3 -8 -7z"/>
<path d="M3340 1735 c0 -43 1 -45 28 -44 23 2 24 2 5 6 -25 5 -32 33 -8 33 8
0 15 5 15 10 0 6 -7 10 -15 10 -23 0 -18 18 8 23 19 4 18 4 -5 6 -27 1 -28 -1
-28 -44z"/>
<path d="M3452 1735 c0 -41 2 -45 7 -20 l7 30 18 -27 c26 -40 36 -35 36 17 0
25 -4 45 -10 45 -5 0 -10 -12 -10 -27 l0 -28 -25 30 -24 30 1 -50z"/>
<path d="M3570 1735 c0 -40 2 -45 23 -45 29 0 47 18 47 45 0 27 -18 45 -47 45
-21 0 -23 -5 -23 -45z m54 13 c7 -12 6 -21 -3 -32 -21 -25 -31 -19 -31 20 0
37 14 42 34 12z"/>
<path d="M3697 1773 c-11 -10 -8 -83 3 -83 6 0 10 20 10 45 0 46 -2 50 -13 38z"/>
<path d="M3771 1735 c1 -40 3 -44 8 -20 l6 29 22 -29 22 -30 -1 50 c-1 30 -3
39 -5 22 l-4 -28 -25 28 -24 28 1 -50z"/>
<path d="M3908 1769 c-22 -12 -23 -52 -4 -69 22 -18 61 -9 61 14 0 11 -6 21
-14 24 -9 3 -11 -1 -6 -17 8 -25 -16 -29 -34 -7 -16 19 4 60 25 52 8 -3 14 -1
14 4 0 13 -20 12 -42 -1z"/>
<path d="M2931 1739 c-16 -45 -14 -51 10 -37 17 10 23 10 26 1 3 -7 8 -13 13
-13 5 0 1 18 -7 40 -9 22 -19 40 -24 40 -4 0 -12 -14 -18 -31z m29 -9 c0 -5
-4 -10 -10 -10 -5 0 -10 5 -10 10 0 6 5 10 10 10 6 0 10 -4 10 -10z"/>
<path d="M5170 1372 c-30 -25 -19 -58 26 -77 26 -11 34 -20 34 -39 0 -20 -5
-26 -23 -26 -13 0 -28 5 -35 12 -6 6 -15 8 -19 4 -14 -14 34 -44 62 -38 56 10
56 72 -1 99 -20 10 -34 24 -34 34 0 24 16 32 39 19 13 -7 22 -7 26 0 3 6 -6
15 -20 20 -33 13 -29 13 -55 -8z"/>
<path d="M2154 1353 c-44 -48 -40 -82 20 -168 42 -59 42 -59 80 -12 55 67 67
104 50 146 -19 45 -41 60 -87 61 -29 0 -44 -7 -63 -27z m114 -66 c-4 -36 -26
-52 -61 -43 -56 14 -59 79 -4 94 42 12 69 -10 65 -51z"/>
<path d="M2203 1309 c-16 -16 -5 -39 19 -39 14 0 19 6 16 22 -4 25 -19 33 -35
17z"/>
<path d="M2370 1298 c0 -52 4 -88 10 -88 6 0 10 28 10 63 l0 62 56 -57 c31
-32 58 -58 60 -58 2 0 4 36 4 80 0 47 -4 80 -10 80 -5 0 -10 -25 -10 -55 0
-30 -3 -55 -7 -55 -5 0 -31 26 -60 57 l-52 58 -1 -87z"/>
<path d="M3600 1305 c0 -68 -2 -75 -20 -75 -23 0 -27 -15 -5 -24 30 -11 45 22
45 100 0 43 -4 74 -10 74 -6 0 -10 -32 -10 -75z"/>
<path d="M3780 1295 c0 -50 4 -85 10 -85 6 0 10 15 10 33 0 41 12 60 36 55 14
-2 20 -13 22 -46 4 -51 22 -57 22 -6 0 55 -16 77 -51 70 -28 -5 -29 -4 -29 29
0 19 -4 35 -10 35 -6 0 -10 -35 -10 -85z"/>
<path d="M5750 1295 c0 -82 1 -85 23 -85 52 0 86 52 52 80 -11 9 -13 16 -5 24
27 27 -2 66 -49 66 -20 0 -21 -6 -21 -85z m54 56 c9 -14 -4 -41 -20 -41 -8 0
-14 10 -14 25 0 25 23 35 34 16z m6 -76 c10 -12 10 -18 0 -30 -21 -25 -40 -18
-40 15 0 33 19 40 40 15z"/>
<path d="M6210 1295 c0 -87 16 -119 22 -42 2 35 7 43 26 45 20 3 22 -1 22 -42
0 -25 5 -46 10 -46 12 0 13 80 1 99 -5 8 -19 12 -35 9 -24 -5 -26 -2 -26 28 0
19 -4 34 -10 34 -6 0 -10 -35 -10 -85z"/>
<path d="M108 1353 c-53 -33 -66 -127 -25 -174 52 -60 117 -66 171 -16 59 56
60 115 1 174 -27 27 -40 33 -76 33 -24 0 -56 -8 -71 -17z m139 -41 c15 -83 14
-87 -7 -109 -16 -18 -32 -23 -70 -23 -28 0 -53 0 -57 0 -4 0 -7 25 -7 55 0 75
22 99 86 93 33 -4 45 -2 41 6 -3 6 -2 8 2 3 5 -4 10 -15 12 -25z"/>
<path d="M163 1289 c9 -5 17 -17 17 -24 0 -8 9 -15 19 -15 11 0 22 -8 24 -17
3 -10 5 -4 6 13 1 38 -15 54 -54 54 -25 -1 -27 -2 -12 -11z"/>
<path d="M135 1251 c6 -41 3 -38 40 -46 l30 -6 -28 22 c-16 12 -33 31 -38 43
-7 16 -8 14 -4 -13z"/>
<path d="M4847 1364 c-8 -8 1 -24 14 -24 5 0 9 7 9 15 0 15 -12 20 -23 9z"/>
<path d="M6320 1355 c0 -9 5 -15 11 -13 6 2 11 8 11 13 0 5 -5 11 -11 13 -6 2
-11 -4 -11 -13z"/>
<path d="M4900 1336 c0 -8 -6 -17 -12 -20 -10 -5 -10 -7 0 -12 7 -3 12 -24 12
-50 0 -24 5 -44 10 -44 6 0 10 20 10 44 0 26 5 47 13 50 10 5 10 7 0 12 -7 3
-13 12 -13 20 0 8 -4 14 -10 14 -5 0 -10 -6 -10 -14z"/>
<path d="M5280 1336 c0 -8 -6 -17 -12 -20 -10 -5 -10 -7 0 -12 7 -3 12 -24 12
-50 0 -24 5 -44 10 -44 6 0 10 20 10 44 0 26 5 47 13 50 10 5 10 7 0 12 -7 3
-13 12 -13 20 0 8 -4 14 -10 14 -5 0 -10 -6 -10 -14z"/>
<path d="M490 1315 c-7 -9 -9 -19 -5 -23 4 -4 10 -1 12 6 7 17 50 15 57 -3 3
-10 -10 -33 -35 -60 l-42 -45 47 0 c25 0 46 5 46 10 0 6 -12 10 -27 10 l-27 0
27 28 c29 30 35 60 15 80 -17 17 -53 15 -68 -3z"/>
<path d="M617 1323 c-15 -15 -5 -22 23 -16 32 6 41 -12 19 -34 -9 -9 -7 -16 5
-29 15 -15 16 -19 3 -31 -11 -12 -19 -12 -35 -4 -29 15 -37 14 -22 -5 8 -9 25
-14 44 -12 30 3 31 4 32 58 0 30 -4 61 -9 68 -10 13 -49 17 -60 5z"/>
<path d="M863 1318 c-13 -21 -13 -112 0 -120 23 -15 65 -9 71 10 6 22 1 96 -8
111 -10 15 -53 14 -63 -1z m55 -21 c4 -20 -25 -34 -40 -19 -15 15 -1 44 19 40
10 -2 19 -11 21 -21z m0 -69 c-2 -15 -10 -23 -23 -23 -13 0 -21 8 -23 23 -3
18 1 22 23 22 22 0 26 -4 23 -22z"/>
<path d="M986 1314 c-18 -18 -21 -76 -6 -105 14 -26 55 -24 74 3 44 63 -17
154 -68 102z m58 -20 c19 -51 -8 -110 -39 -84 -21 18 -27 44 -16 74 12 29 45
36 55 10z"/>
<path d="M1113 1324 c-25 -11 -13 -23 16 -18 35 7 47 -8 24 -31 -16 -16 -16
-18 0 -27 9 -5 17 -16 17 -24 0 -20 -27 -29 -45 -14 -17 14 -31 8 -20 -9 9
-16 61 -13 75 4 7 9 9 22 4 32 -4 10 -8 34 -8 53 -1 30 -5 35 -26 37 -14 1
-31 -1 -37 -3z"/>
<path d="M1230 1310 c-7 -14 -7 -20 0 -20 5 0 10 5 10 10 0 16 47 11 54 -5 3
-9 -8 -31 -29 -54 -19 -21 -35 -41 -35 -45 0 -3 18 -6 40 -6 22 0 40 5 40 10
0 6 -9 10 -20 10 -11 0 -20 4 -20 8 0 5 9 17 20 27 22 20 26 55 8 73 -19 19
-56 14 -68 -8z"/>
<path d="M1334 1319 c-3 -5 13 -9 35 -9 23 0 41 -3 41 -6 0 -3 -13 -24 -28
-47 -41 -60 -44 -67 -34 -67 9 0 80 101 84 121 4 13 -89 21 -98 8z"/>
<path d="M1480 1315 c-18 -22 -4 -31 16 -11 12 13 19 14 30 5 22 -18 17 -36
-22 -79 l-36 -40 46 0 c29 0 45 4 41 10 -3 6 -16 10 -27 10 -21 0 -21 1 5 28
31 32 34 54 11 76 -20 20 -48 20 -64 1z"/>
<path d="M1600 1315 c-17 -21 -18 -89 -1 -109 17 -21 55 -21 72 0 7 9 14 33
14 54 0 21 -7 45 -14 54 -17 20 -55 21 -71 1z m66 -31 c10 -37 -7 -79 -31 -79
-24 0 -41 42 -31 79 5 19 13 26 31 26 18 0 26 -7 31 -26z"/>
<path d="M1710 1319 c-11 -6 -2 -9 28 -9 23 0 42 -3 42 -6 0 -3 -13 -24 -28
-47 -41 -60 -44 -67 -34 -67 12 0 92 122 87 131 -7 10 -79 9 -95 -2z"/>
<path d="M1964 1294 c-22 -53 -2 -104 42 -104 33 0 58 41 50 82 -8 41 -23 58
-53 58 -18 0 -28 -9 -39 -36z m72 -9 c8 -33 -2 -71 -21 -78 -19 -8 -45 18 -45
44 0 55 54 83 66 34z"/>
<path d="M388 1314 c-5 -4 -8 -16 -8 -26 0 -11 -7 -18 -19 -18 -11 0 -23 -4
-26 -10 -4 -6 6 -10 23 -10 25 0 29 -4 25 -19 -3 -10 0 -22 6 -25 7 -5 11 3
11 18 0 21 5 26 25 26 14 0 25 5 25 10 0 6 -11 10 -24 10 -19 0 -25 6 -28 26
-2 14 -6 22 -10 18z"/>
<path d="M757 1270 l-38 -50 35 0 c25 0 36 -4 36 -15 0 -20 18 -19 22 1 5 26
-3 114 -10 114 -4 -1 -24 -23 -45 -50z m33 -15 c0 -18 -5 -25 -20 -25 -24 0
-25 8 -3 32 21 23 23 23 23 -7z"/>
<path d="M1872 1272 l-44 -52 36 0 c25 0 36 -4 36 -15 0 -21 17 -19 21 3 3 9
2 39 -1 66 l-5 50 -43 -52z m28 -17 c0 -18 -5 -25 -20 -25 -20 0 -20 1 -4 25
9 14 18 25 20 25 2 0 4 -11 4 -25z"/>
<path d="M2556 1304 c-20 -19 -21 -68 -2 -83 7 -6 32 -10 55 -9 l41 1 0 52 0
52 -39 1 c-25 1 -46 -4 -55 -14z m74 -23 c15 -28 -21 -71 -47 -55 -20 12 -28
37 -19 60 8 21 53 17 66 -5z"/>
<path d="M2676 1304 c-3 -9 4 -24 19 -38 30 -28 33 -50 5 -41 -11 4 -20 2 -20
-4 0 -17 50 -13 57 5 3 9 -6 27 -23 45 -27 28 -27 29 -6 29 12 0 22 5 22 10 0
16 -47 11 -54 -6z"/>
<path d="M2755 1303 c-4 -12 2 -24 19 -38 35 -28 34 -49 0 -41 -17 5 -23 3
-19 -4 10 -16 55 -12 62 7 4 10 -3 24 -20 39 -32 28 -34 34 -7 34 11 0 20 5
20 10 0 17 -48 11 -55 -7z"/>
<path d="M2846 1301 c-18 -20 -20 -44 -6 -72 9 -15 20 -19 60 -17 l50 2 0 51
0 51 -44 2 c-31 1 -48 -4 -60 -17z m80 -26 c12 -48 -44 -77 -66 -35 -17 33 -3
60 30 60 23 0 31 -5 36 -25z"/>
<path d="M2970 1263 c0 -29 5 -53 10 -53 6 0 10 13 10 29 0 39 9 61 26 61 8 0
14 4 14 8 0 5 -13 9 -30 9 -30 0 -30 -1 -30 -54z"/>
<path d="M3054 1295 c-24 -36 -3 -85 35 -85 15 0 32 5 39 12 9 9 12 9 12 0 0
-7 5 -12 10 -12 6 0 10 24 10 53 l0 53 -45 2 c-38 1 -47 -2 -61 -23z m82 -21
c8 -33 -15 -59 -44 -50 -46 15 -34 76 14 76 17 0 25 -7 30 -26z"/>
<path d="M3180 1315 c0 -12 45 -95 51 -95 4 0 11 9 14 20 9 28 19 25 31 -7
l10 -28 17 32 c26 50 37 83 28 83 -5 0 -14 -13 -21 -30 -15 -37 -28 -38 -36
-5 -3 14 -10 25 -14 25 -4 0 -11 -11 -14 -25 -9 -34 -25 -32 -37 5 -6 17 -15
30 -20 30 -5 0 -9 -2 -9 -5z"/>
<path d="M3357 1302 c-31 -34 -13 -93 28 -93 65 1 70 5 70 56 l0 48 -41 4
c-30 3 -45 -1 -57 -15z m73 -17 c28 -34 -26 -84 -57 -52 -23 23 -9 67 21 67
13 0 29 -7 36 -15z"/>
<path d="M3653 1294 c-15 -24 -15 -29 -2 -53 16 -29 66 -41 80 -19 7 10 9 10
9 1 0 -7 5 -13 10 -13 6 0 10 25 10 55 0 30 -4 55 -10 55 -5 0 -10 -5 -10 -12
0 -9 -3 -9 -12 0 -21 21 -56 14 -75 -14z m85 -25 c4 -31 -20 -53 -46 -44 -44
13 -30 78 16 73 21 -2 28 -9 30 -29z"/>
<path d="M3890 1278 c0 -62 22 -84 68 -67 18 7 22 16 22 50 0 51 7 48 14 -5 6
-50 16 -59 16 -13 0 43 25 74 46 56 8 -6 14 -29 14 -51 0 -24 4 -37 10 -33 14
9 12 82 -2 92 -7 4 -36 8 -65 8 l-52 1 -3 -46 c-3 -37 -6 -45 -23 -45 -17 0
-20 8 -23 48 -4 58 -22 63 -22 5z"/>
<path d="M4230 1262 c0 -34 4 -51 10 -47 6 4 10 14 9 23 -4 49 1 62 26 62 23
0 25 -4 25 -45 0 -25 5 -45 10 -45 6 0 10 22 10 50 0 53 -9 60 -67 57 -21 -1
-23 -6 -23 -55z"/>
<path d="M4347 1302 c-20 -22 -22 -52 -4 -76 15 -21 78 -19 85 2 3 11 1 11
-17 0 -15 -9 -26 -10 -38 -2 -34 21 -26 34 21 34 38 0 46 3 46 18 0 42 -63 58
-93 24z m68 -12 c4 -6 -8 -10 -30 -10 -22 0 -34 4 -30 10 3 6 17 10 30 10 13
0 27 -4 30 -10z"/>
<path d="M4472 1308 c-7 -7 -12 -27 -12 -44 0 -40 31 -67 64 -55 11 5 27 6 34
4 8 -3 12 10 12 49 l0 54 -43 2 c-24 1 -49 -4 -55 -10z m78 -38 c0 -31 -11
-50 -29 -50 -39 0 -57 60 -24 79 23 12 53 -4 53 -29z"/>
<path d="M4600 1263 c0 -29 5 -53 10 -53 6 0 10 13 10 29 0 39 9 61 26 61 8 0
14 4 14 8 0 5 -13 9 -30 9 -30 0 -30 -1 -30 -54z"/>
<path d="M4754 1295 c-14 -21 -15 -31 -5 -51 11 -26 47 -45 69 -37 20 8 14 25
-7 18 -20 -6 -51 17 -51 39 0 19 22 36 47 36 13 0 23 5 23 10 0 6 -13 10 -30
10 -21 0 -35 -8 -46 -25z"/>
<path d="M4850 1265 c0 -30 5 -55 10 -55 6 0 10 25 10 55 0 30 -4 55 -10 55
-5 0 -10 -25 -10 -55z"/>
<path d="M4975 1276 c25 -43 26 -46 10 -76 -9 -18 -14 -34 -11 -38 8 -8 9 -6
50 75 21 42 32 75 26 78 -5 3 -15 -5 -22 -19 -14 -32 -21 -32 -42 -1 -29 44
-38 27 -11 -19z"/>
<path d="M5343 1294 c-15 -24 -15 -29 -2 -53 11 -20 22 -26 49 -26 27 0 38 6
49 26 13 24 13 29 -2 53 -12 18 -26 26 -47 26 -21 0 -35 -8 -47 -26z m78 -10
c17 -21 0 -49 -31 -49 -31 0 -48 28 -31 49 8 9 21 16 31 16 10 0 23 -7 31 -16z"/>
<path d="M5460 1269 c0 -26 5 -51 10 -54 9 -6 15 16 11 39 -3 18 11 46 24 46
8 0 16 4 19 9 3 5 -10 9 -29 8 -35 -1 -35 -1 -35 -48z"/>
<path d="M5543 1294 c-15 -24 -15 -29 -2 -53 9 -16 25 -27 43 -29 34 -4 59 5
51 18 -4 6 -13 5 -25 -2 -14 -9 -22 -9 -34 1 -27 22 -18 31 29 31 49 0 58 15
27 43 -28 26 -69 21 -89 -9z m77 -5 c0 -5 -15 -9 -32 -9 -31 1 -32 2 -15 15
19 14 47 10 47 -6z"/>
<path d="M5877 1302 c-20 -22 -22 -52 -4 -76 10 -13 23 -16 57 -13 l45 4 0 48
0 48 -41 4 c-30 3 -45 -1 -57 -15z m78 -22 c12 -39 -21 -68 -56 -50 -23 13
-25 43 -4 63 18 19 52 11 60 -13z"/>
<path d="M5999 1303 c0 -10 -2 -25 -3 -33 -2 -8 0 -25 5 -37 9 -26 56 -32 77
-11 14 14 16 98 2 98 -5 0 -10 -17 -10 -37 0 -46 -13 -68 -34 -60 -11 4 -16
19 -16 46 0 39 -19 70 -21 34z"/>
<path d="M6117 1302 c-33 -37 -13 -92 33 -92 16 0 32 5 36 11 4 8 -1 9 -15 4
-13 -4 -28 -1 -36 5 -33 27 -4 83 36 73 15 -4 20 -3 15 5 -11 18 -51 14 -69
-6z"/>
<path d="M6320 1265 c0 -30 5 -55 10 -55 6 0 10 25 10 55 0 30 -4 55 -10 55
-5 0 -10 -25 -10 -55z"/>
<path d="M4105 1209 c-9 -42 0 -47 19 -11 14 28 13 42 -5 42 -4 0 -10 -14 -14
-31z"/>
</g>
      </svg>
    </Box>
  );
  if (disabledLink) {
    return <>{logo}</>;
  }

  return <RouterLink to="">{logo}</RouterLink>;
}
